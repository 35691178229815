<ng-template #confirmModal let-modal>
    <div class="modal-header m-auto">
      <span *ngIf="type == 'default'" class="mdi mdi-information-outline text-secondary icon-img"></span>
      <span *ngIf="type == 'info'" class="mdi mdi-information-outline text-info icon-img"></span>
      <span *ngIf="type == 'success'" class="mdi mdi-progress-check text-success icon-img"></span>
      <span *ngIf="type == 'warning'" class="mdi mdi-alert-circle-outline text-warning icon-img"></span>
      <span *ngIf="type == 'danger'" class="mdi mdi-alert-outline text-danger icon-img"></span>
    </div>
    <div class="modal-body text-center">
      <b class="mb-2">{{title | translate}}</b>
      <p class="sub-title">{{content | translate}}</p>
      <div class="mx-auto">
        <button type="button" class="btn btn-light p-2" (click)="modal.close('Save click')">{{'global_back' | translate}}</button>
      </div>
    </div>
</ng-template>
<div class="card full-height">
    <div class="card-body">
        <div class="detail-page">
            
                <div class="title" *ngIf="showTitle">
                    <span>{{title | translate}}</span>
                </div> 
                <div class="mb-2" *ngIf="showSearch">
                    <searchbox [value]="searchKey" (onSearch)="search($event)"></searchbox>
                </div>
            <perfect-scrollbar [config]="scrollbarConfig" [ngClass]="{
                    'height-perf': !showTitle && !showSearch && !showPagination, 
                    'height-perf-single-header': ((showTitle && !showSearch) || (!showTitle && showSearch)) && !showPagination, 
                    'height-perf-multi-header': showTitle && showSearch && !showPagination, 
                    'height-perf-single-header-pagination': ((showTitle && !showSearch) || (!showTitle && showSearch)) && showPagination, 
                    'height-perf-pagination': !showTitle && !showSearch && showPagination, 
                    'height-perf-all': showTitle && showSearch && showPagination
            }">
                <div class="table-responsive">
                    <table class="table table-borderless table-hover">
                        <!-- <tbody *ngIf="data.length > 0 && showPagination">
                            <tr *ngFor="let layer of data | slice: (page-1) * pageSize : page * pageSize" 
                                [ngClass]="{ 'active-row' : checkActiveLayerlist(layer)}" 
                                class="cursor-pointer list-object" 
                                (click)="onSelect(layer)">
                                <td [tooltip]="layer.name">
                                    <div class="row py-0" *ngIf="icon != 'none'">
                                        <div class="col-2 py-0 text-center">
                                            <img *ngIf="icon == 'current' && checkAvatarUrl(layer.iconUrl)" src="{{layer.iconUrl}}" class="img-fluid layerlist-img img-30" alt="">
                                            <img *ngIf="(icon == 'current' && !checkAvatarUrl(layer.iconUrl)) || icon == 'default'" src="{{defaultIconUrl}}" class="img-fluid layerlist-img img-30" alt="">
                                            <img *ngIf="icon == 'custom'" src="{{customIconPath}}" class="img-fluid layerlist-img img-30" alt="">
                                        </div>
                                        <div class="col-10 py-0">
                                            <p class="title text-overflow">{{layer.name}}</p>
                                            <p class="sub-title">{{layer.code}}</p>
                                        </div>
                                    </div>
                                    <div class="row py-0" *ngIf="icon == 'none'">
                                        <div class="col-12 py-0">
                                            <p class="title text-overflow">{{layer.name}}</p>
                                            <p class="sub-title">{{layer.code}}</p>
                                        </div>
                                    </div>
                                </td>                                     
                            </tr>
                        </tbody>  -->
                        <tbody *ngIf="data.length > 0">
                            <tr *ngFor="let layer of data" 
                                [ngClass]="{ 'active-row' : checkActiveLayerlist(layer)}" 
                                class="cursor-pointer list-object" 
                                (click)="onSelect(layer)">
                                <td [tooltip]="layer.name">
                                    <div class="row py-0" *ngIf="icon != 'none'">
                                        <div class="col-2 py-0 text-center">
                                            <img *ngIf="icon == 'current' && checkAvatarUrl(layer.iconUrl)" src="{{layer.iconUrl}}" class="img-fluid layerlist-img img-30" alt="">
                                            <img *ngIf="(icon == 'current' && !checkAvatarUrl(layer.iconUrl)) || icon == 'default'" src="{{defaultIconUrl}}" class="img-fluid layerlist-img img-30" alt="">
                                            <img *ngIf="icon == 'custom'" src="{{customIconPath}}" class="img-fluid layerlist-img img-30" alt="">
                                        </div>
                                        <div class="col-10 py-0">
                                            <p class="title text-overflow">{{layer.name}}</p>
                                            <p *ngIf="showCodeAsRowSubtitle" class="sub-title">{{layer.code}}</p>
                                        </div>
                                    </div>
                                    <div class="row py-0" *ngIf="icon == 'none'">
                                        <div class="col-12 py-0">
                                            <p class="title text-overflow">{{layer.name}}</p>
                                            <p *ngIf="showCodeAsRowSubtitle" class="sub-title">{{layer.code}}</p>
                                        </div>
                                    </div>
                                </td>                                     
                            </tr>
                        </tbody> 
                    </table>
                    <div class="mt-2" *ngIf="data.length == 0">
                        <i class="not-found-content">{{'global_not-found' | translate}}</i>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    <div class="card-footer" *ngIf="data.length > 0 && showPagination">
        <pagination [pageNumber]="page" [pageSize]="pageSize" [totalData]="totalData" [currentPageData]="data" (pageChange)="changePage($event)"></pagination>
    </div>
</div>

import { Injectable } from '@angular/core';
import { Location } from '@angular/common'; 
import { ChangeURLModel, DataMinModel, DateTimeModel } from '@shared/models/global.model';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class BasicService {
  history: string[] = [];
  historyEvt: Subscription;
  
  constructor( private location: Location, private routerService: Router, private translate: TranslateService) { 
    this.historyEvt = this.routerService.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    })
  }

  ngOnDestroy(): void {
    this.historyEvt.unsubscribe();
  }

  replaceUrlParams(currentUrl: string, params: ChangeURLModel[]){
    let newUrl = currentUrl + "?";
    for (let item of params){
      newUrl += item.key + "=" + item.value + "&";
    }
    newUrl = newUrl.replace(/.$/,"");
    
    this.location.replaceState(newUrl);
  }

  replaceUrlAfterSearch(currentUrl, page, sort, sortBy, searchKey){
    let data = [{
      key: "page",
      value: page
    },{
      key: "sort",
      value: sort
    },{
      key: "sortBy",
      value: sortBy
    },{
      key: "searchKey",
      value: searchKey
    }] as ChangeURLModel[];

    this.replaceUrlParams(currentUrl, data);
  }

  generateMonthsOfYear(){
    let data = [{
      id: "january",
      value: "1"
    },{
      id: "february",
      value: "2"
    },{
      id: "match",
      value: "3"
    },{
      id: "april",
      value: "4"
    },{
      id: "may",
      value: "5"
    },{
      id: "june",
      value: "6"
    },{
      id: "july",
      value: "7"
    },{
      id: "august",
      value: "8"
    },{
      id: "september",
      value: "9"
    },{
      id: "october",
      value: "10"
    },{
      id: "november",
      value: "11"
    },{
      id: "december",
      value: "12"
    }] as DateTimeModel[];
    return data;
  }

  generateQuartersOfYear(){
    let data = [{
      id: "1stQuarter",
      value: "1"
    },{
      id: "2ndQuarter",
      value: "2"
    },{
      id: "3rdQuarter",
      value: "3"
    },{
      id: "4thQuater",
      value: "4"
    }] as DateTimeModel[];
    return data;
  }

  backToPreviousPage(replaceUrl: string){
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.routerService.navigate([replaceUrl], {queryParams: {}});
    }
  }

  setPaginationTitle(total: number, currentPageDataLength: number, pageSize: number, page: number) {
    let str = "";
    let from = (page - 1) * pageSize + 1;
    let to = page * pageSize;
    
    if (total == currentPageDataLength) str = this.translate.instant("global_pagination_title").replace("{{from}}", from).replace("{{to}}", currentPageDataLength).replace("{{all}}", total);
    if (total > currentPageDataLength) {
      if (to < total) str = this.translate.instant("global_pagination_title").replace("{{from}}", from).replace("{{to}}", to).replace("{{all}}", total);
      else str = this.translate.instant("global_pagination_title").replace("{{from}}", from).replace("{{to}}", total).replace("{{all}}", total);
    }
    return str;
  }

  getListMonths() :DataMinModel[]{
    let listMonths = [] as DataMinModel[];
    let data = this.generateMonthsOfYear();
    for (let item of data) {
        listMonths.push({
            id: item.id,
            name: this.translate ? this.translate.instant("global_month-replace").replace("{month}", item.value) : `Tháng ${item.value}`,
            code: item.value
        })
    }
    return listMonths;
  }

  getListQuarters() :DataMinModel[]{
    let listQuarters = [] as DataMinModel[];
    let data = this.generateQuartersOfYear();
    for (let item of data) {
      listQuarters.push({
        id: item.id,
        name: this.translate ? this.translate.instant("global_quarter-replace").replace("{quarter}", item.value) : `Quý ${item.value}`,
        code: item.value
      })
    }
    return listQuarters;
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { D2dModel } from '@shared/models/global.model';

@Component({
  selector: 'd2d-selection',
  templateUrl: './d2d-selection.component.html',
  styleUrls: ['./d2d-selection.component.scss']
})
export class D2dSelectionComponent implements OnInit {

  @Output() onSelectionCompleted = new EventEmitter<object>();
  @Input() size: string;
  @Input() title: string;

  @ViewChild('d2dSelectionModal') modal: any;
  closeResult: string;
  modalReference: NgbModalRef;

  fromDate: string = "";
  toDate: string = "";
  type: string = "";

  constructor(private modalService: NgbModal) { 
    if (!this.size) this.size = 'md'; //size: sm, md, lg
    if (!this.title) this.title = 'global-select-d2d';
  }

  ngOnInit(): void {
    
  }

  open(data: D2dModel) {
    this.modalReference = this.modalService.open(this.modal, {
      ariaLabelledBy: 'modal-basic-title', 
      centered: false,
      size: this.size
    });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.fromDate = data.fromDate;
    this.toDate = data.toDate;
    this.type = data.type ? data.type : null;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  close(){
    this.modalReference.close();
  }

  submit(){
    this.onSelectionCompleted.emit({
      fromDate: this.fromDate,
      toDate: this.toDate,
      type: this.type
    } as D2dModel);
    this.close();
  } 

  isDisabledBtn(){
    if (!this.fromDate || !this.toDate) return true;
    if (this.fromDate && this.toDate && (this.fromDate > this.toDate)) return true;
    return false;
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Basic } from '@core/lib/basic';

@Component({
  selector: 'searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss']
})
export class SearchboxComponent implements OnInit {
  @Input() id: string;
  @Input() timer: number;
  @Input() value: string;
  @Output() onSearch = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    if (!this.timer) this.timer = 300;
    if (!this.id) this.id = Basic.uuidGenerator();
  }
  
  ngAfterViewInit(){
    this.initSearch(this.id);
  }

  initSearch(divId){
    let searchDom = document.getElementById(divId) as HTMLInputElement;
    searchDom.addEventListener("keydown", this.delay(function (e) {
      if (searchDom.value){
        this.onSearch.emit({
          search_string: encodeURIComponent(searchDom.value.trim()) 
        })
      } else {
        this.onSearch.emit({
          search_string: ""
        })
      }
    }, this.timer).bind(this));
  }

  delay(callback, ms) {
    var timer;
    return function() {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          callback.apply(context, args);
        }, ms || 0);
    };
  }

  refresh(){
    this.value = "";
    let searchDom = document.getElementById(this.id) as HTMLInputElement;
    searchDom.value = this.value;
  }
}

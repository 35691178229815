import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CacheAll } from '@core/lib/cache';
import { UserService } from '../../pages/user/shared/services/user.service';
import { ApiReturnModel } from '@shared/models/global.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CacheConst } from '@core/constant/shared/system.const';
import { AuthService } from '@core/services/root/auth/auth.service';

@Component({
  selector: 'role-info-modal',
  templateUrl: './role-info-modal.component.html',
  styleUrls: ['./role-info-modal.component.scss']
})
export class RoleInfoModalComponent implements OnInit {

  @Output() onClick = new EventEmitter<object>();
  @Input() centerPage:boolean;
  @Input() size: string;
  
  @ViewChild('roleInforModal') modal: any;
  
  closeResult: string;
  modalReference: NgbModalRef;
  confirmData: any;
  isDisabled: boolean = true;
  fileUpload: any;
  token: string
  imgUrl: string | ArrayBuffer;

  constructor(public authService: AuthService, private modalService: NgbModal, public userService: UserService, public toast: ToastrService, public translate: TranslateService) { 
   
  }

  ngOnInit(): void {
    this.token = CacheAll.getCookie(CacheConst.USER_TOKEN);
    if (!this.centerPage) this.centerPage = false;
    if (!this.size) this.size = 'md'; //size: sm, md, lg
  }

  clickLink(){
    this.userService.uploadAvatar(this.fileUpload).subscribe(rs => {
      let result = rs as ApiReturnModel;
      if (result.code == 0) {
        let avtUrl = result.data[0].downloadFileURI;
        this.onClick.emit(avtUrl);
      } else {
        if (!this.authService.checkInvalidResponse(result)) this.toast.error(result.message);
      }
    }, error => {
      this.toast.error(this.translate.instant("user-upload_avatar-fail"));
      this.onClick.emit(null);
    })
  }

  open() {
    this.modalReference = this.modalService.open(this.modal, {
      ariaLabelledBy: 'modal-basic-title', 
      centered: this.centerPage,
      size: this.size
    });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.imgUrl = "";
    this.isDisabled = true;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  close(){
    this.modalReference.close();
  }


}

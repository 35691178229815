export enum RegexType {
  PHONE = "PHONE_NUMBER",
  TEXT = "ALPHA_DASH_VN",
  CODE = "CODE",
  MAIL = "EMAIL",
  NUM_INT = "INTEGER",
  NUM_DEC = "DECIMAL",
  DATE = "DATE",
  DATE_TIME = "DATE_TIME",
  NONE = ""
}

export enum ComponentUiEnum {
  TEXT_VIEW = "TextView",
  TEXT_AREA = "TextArea",
  INPUT_TEXT = "InputText",
  INPUT_FILE = "InputFile",
  CALENDAR_DATE = "Calendar(Date)",
  CALENDAR_DATETIME = "Calendar(DateTime)",
  CALENDAR_TIME = "Calendar(Time)",
  COMBOBOX_SINGLE = "Combobox(SingleChoice)",
  COMBOBOX_MULTIPLE = "Combobox(MultiChoice)",
  HIDDEN_VIEW = "HiddenView"
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { SelectboxComponent } from './selectbox/selectbox.component';
import { NotifyModalComponent } from './notify-modal/notify-modal.component';
import { SearchboxComponent } from './searchbox/searchbox.component';
import { ConfirmInputModalComponent } from './confirm-input-modal/confirm-input-modal.component';
import { ChangeAvatarModalComponent } from './change-avatar-modal/change-avatar-modal.component';

import { SelectboxWSearchComponent } from './selectbox-w-search/selectbox-w-search.component';

import { RoleInfoModalComponent } from './role-info-modal/role-info-modal.component';
import { RequiredNotifyComponent } from './required-notify/required-notify.component';
import { ViewAttachFileModalComponent } from './view-attach-file-modal/view-attach-file-modal.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PageHeaderComponent } from './page-header/page-header.component';
import { RouterModule } from '@angular/router';
import { IconSelectboxComponent } from './icon-selectbox/icon-selectbox.component';
import { PaginationComponent } from './pagination/pagination.component';
import { IframeModalComponent } from './iframe-modal/iframe-modal.component';
import { D2dSelectionComponent } from './d2d-selection/d2d-selection.component';
import { ObjClassMenuComponent } from './obj-class-menu/obj-class-menu.component';
import { UploadFileModalComponent } from './upload-file-modal/upload-file-modal.component';
import { SortArrowComponent } from './sort-arrow/sort-arrow.component';
import { FilterWidgetComponent } from './filter-widget/filter-widget.component';
import { SearchableSelectboxComponent } from './searchable-selectbox/searchable-selectbox.component';
import { MonthPickerComponent } from './month-picker/month-picker.component';

@NgModule({
  declarations: [
    ConfirmModalComponent, 
    SelectboxComponent, 
    NotifyModalComponent, 
    SearchboxComponent, 
    ConfirmInputModalComponent, 
    ChangeAvatarModalComponent, 
    SelectboxWSearchComponent, 
    RoleInfoModalComponent,
    RequiredNotifyComponent,
    ViewAttachFileModalComponent,
    PageHeaderComponent,
    IconSelectboxComponent,
    PaginationComponent,
    IframeModalComponent,
    D2dSelectionComponent,
    ObjClassMenuComponent,
    UploadFileModalComponent,
    SortArrowComponent,
    FilterWidgetComponent,
    SearchableSelectboxComponent,
    MonthPickerComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxDocViewerModule,
    RouterModule
  ],
  exports: [
    ConfirmModalComponent,
    SelectboxComponent,
    NotifyModalComponent,
    SearchboxComponent, 
    SelectboxWSearchComponent,
    ConfirmInputModalComponent,
    ChangeAvatarModalComponent,
    RoleInfoModalComponent,
    RequiredNotifyComponent,
    ViewAttachFileModalComponent,
    PageHeaderComponent,
    IconSelectboxComponent,
    PaginationComponent,
    IframeModalComponent,
    D2dSelectionComponent,
    ObjClassMenuComponent,
    UploadFileModalComponent,
    SortArrowComponent,
    FilterWidgetComponent,
    SearchableSelectboxComponent,
    MonthPickerComponent
  ]
})
export class ReusedModule { }

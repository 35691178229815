import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Basic } from '@core/lib/basic';
import { ApiReturnModel, DataMinModel } from '@shared/models/global.model';
import { MainService } from '@core/services/root/main/main.service';

@Component({
  selector: 'searchable-selectbox',
  templateUrl: './searchable-selectbox.component.html',
  styleUrls: ['./searchable-selectbox.component.scss']
})
export class SearchableSelectboxComponent implements OnInit {
  @Input() url: any; //get data to render selectbox
  @Input() title: string; // default title of selectbox
  @Input() titleByCode: string; // custom title of selectbox by "Key" have same value with Code field
  @Input() disabled: boolean; // disabled selectbox if True
  @Input() showTitleAsSelection: boolean; // add another selection as title if True
  @Input() showOptionByFields: string[]; // show options for selectbox by custom fields. Default "[name]"
  @Input() selection: any; //selection data
  @Output() onSelect = new EventEmitter<any>(); // selection selectbox event

  searchKey: string = "";
  searchId: string = "";

  scrollbarConfig: PerfectScrollbarConfigInterface =  {};
  firstData: any = [];
  showingData: any = [];
  firstTitle: string = "";
  defaultSelectionData: DataMinModel;

  constructor(private mainService: MainService) {}

  ngOnInit(): void {
    if (typeof this.disabled != "boolean") this.disabled = false;
    if (typeof this.showTitleAsSelection != "boolean") this.showTitleAsSelection = false;
    this.firstTitle =  (' ' + this.title).slice(1);    
    this.defaultSelectionData = {
      name: this.firstTitle,
      code: "",
      id: ""
    }
    this.searchId = Basic.uuidGenerator();
    if (!this.showOptionByFields) this.showOptionByFields = ["name"];
    this.loadData();
  }

  loadData(){
    this.mainService.getDataForSelectboxByURL(this.url).subscribe(rs => {
      let result = rs as ApiReturnModel;
      if (result.code == 0) {
        this.firstData = Basic.deepClone(result.data);     
        this.showingData = result.data;
        if (this.titleByCode) this.checkAndCustomTitle();
      }
    })
  }

  ngOnChanges(): void {
    if (!this.firstTitle && this.title) this.firstTitle =  (' ' + this.title).slice(1); //deep clone 1st title
    if (!this.selection) this.refresh();

  }

  onClickDropdown(){
    this.searchKey = "";
    let inputElm = document.getElementById(this.searchId) as HTMLInputElement;
    inputElm.value = this.searchKey;
    this.showingData = Basic.deepClone(this.firstData);
  }

  onChange($event){
    let id = $event.target.value;
    let data;
    for (let item of this.firstData){
      if (item.id === id) data = item;
    }
    this.onSelect.emit({
      id: id,
      data: data
    })
  }

  selectedRow(data){
    if (data !== this.defaultSelectionData) this.title = this.renderRowTitle(data);
    else this.title = data.name;
    this.onSelect.emit({
      id: data.id,
      data: data
    })
  }

  onKeyUpSearch($event){
    this.searchKey = decodeURIComponent($event.search_string);
    if (this.searchKey !== "") this.searchData(this.searchKey);
    else this.showingData = Basic.deepClone(this.firstData);
  }

  searchData(key: string){
    this.showingData = [];
    for (let item of this.firstData){
      let name = this.renderRowTitle(item);
      if (name.toLowerCase().includes(key.toLowerCase())) this.showingData.push(item);
    }
  }

  refresh(){
    this.title = this.firstTitle;
  }

  renderRowTitle(data) {
    let title = "";
    for (let item of this.showOptionByFields) {
      title += data[item] + " ";
    }
    title = title.substring(0, title.length - 1);
    return title;
  }

  checkAndCustomTitle(){
    for (let item of this.firstData) {
      if (item["code"] === this.titleByCode) {
        this.title = this.renderRowTitle(item);
        break;
      }
    }
  }
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FilterWidgetModel } from '@shared/models/auto-render.model';
import { ComponentUiEnum } from '@shared/enums/auto-form.enum';
import { ObjFieldModel } from '@shared/models/main.model';

@Component({
  selector: 'filter-widget',
  templateUrl: './filter-widget.component.html',
  styleUrls: ['./filter-widget.component.scss']
})
export class FilterWidgetComponent implements OnInit {

  @Input() objFields: ObjFieldModel[];
  @Output() onFilter = new EventEmitter<FilterWidgetModel[]>();

  isAppliedFilter: boolean = false;

  filterFields: FilterWidgetModel[] = [];
  allowFilterList = ["QueryField","QueryFieldList","QueryFieldContain"]

  componentUiEnum = ComponentUiEnum;

  constructor() { }

  ngOnInit(): void {
    
  }

  ngOnChanges(): void {
    if (this.objFields.length) this.initFilterFields();
  }

  initFilterFields(){
    this.filterFields = [];
    this.objFields.map((field) => {
      if (this.allowFilterList.includes(field.allowFilter)) 
        this.filterFields.push({
          id: field.id,
          code: field.code,
          componentUI: field.componentUI,
          value: null,
          name: field.name,
          apiUrl: field.apiURL
        });
    })
  }

  trackById(index, data) {
    return data.id;
  }

  filter(){
    this.isAppliedFilter = true;
    this.onFilter.emit(this.filterFields);
  }

  cancel() {
    this.isAppliedFilter = false;
    this.initFilterFields();
    this.onFilter.emit([]);
  }

}

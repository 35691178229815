import { UsersModel } from '@shared/models/pages/system-management/user.model';

export class GroupPermissionModel {
  id: string;
  code : string;
  createdAt : string;
  name: string;
  status: number;
  statusUpdAt: string;
  updatedAt: string;
}

export class GroupPermissionActionModel {
  code : string;
  name: string;
}

export class PermissionObjectModel {
  id: string;
  type : string;
  createdAt : string;
  name: string;
  status: number;
  statusUpdAt: string;
  updatedAt: string;
}

export class PermissionObjectActionModel {
  type : string;
  name: string;
}

export class ActionModel {
  view : number;
  create : number;
  edit : number;
  delete : number;
  sync : number;
}

export class PermissionModel {
  id: number;
  module: string;
  permission_name : string;
  permission_url : string;
  permission_alias : string;
  permission_action : ActionModel;
}

export class _PermissionModel {
  allowCreate: number
  allowDelete: number
  ​​​allowUpdate: number
  ​​​allowView: number
  ​​​createdAt: string
  ​​​id: string
  objClass: ObjClassModel
  ​​​permissionGroup: GroupPermissionModel
  ​​​updatedAt: string
}

export class PermissionActionModel {
  allowCreate: number
  allowDelete: number
  ​​​allowUpdate: number
  ​​​allowView: number
  objClass: ObjClassModel
  ​​​permissionGroup: GroupPermissionModel
}

export class UserPermissionModel {
  id: string;
  ​​​permissionGroup: GroupPermissionModel;
  user: UsersModel
}

export class UserPermissionActionModel {
  ​​​permissionGroup: GroupPermissionModel;
  user: UsersModel
}

export class ObjClassModel {
  ​​​code: string;
  createdAt: string;
  funcModuleCode: string;
  hasPerm: number; ​​
  hasShape: number;  ​​
  iconUrl: string;  ​​
  id: string;​​
  layerUrl: string;  ​​
  name: string; ​​
  nameEn: string;  ​​
  shapeType: string;  ​​
  subCode: string;
}

export class ObjClassActionModel {
  ​​​code: string;
  subCode: string;
  name: string; ​​
  nameEn: string; 
  hasPerm: number; ​​
  hasShape: number;
  funcModuleCode: string;
  ​​shapeType: string;
  iconUrl: string;  ​​
  layerUrl: string;  ​​
}

export class FuncModuleModel {
  id: string;
  createdAt: string;
  code: string;
  name: string;
  nameEn: string;
}

export class UserPermisisonModel {
  constructor() {
  }
  allowView: number;
  allowCreate: number;
  allowUpdate: number;
  allowDelete: number;
  permissionGroup: GroupPermissionModel;
  objClass: ObjClassModel;
  status: number;
  id: string;
  statusUpdAt: string;
  createdAt: string;
  updatedAt: string;
}
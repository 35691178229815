import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'view-attach-file-modal',
  templateUrl: './view-attach-file-modal.component.html',
  styleUrls: ['./view-attach-file-modal.component.scss']
})
export class ViewAttachFileModalComponent implements OnInit {
  @Input() title: string;
  @Input() notFoundContent: string;
  @Input() size: string;
  @Input() fileType: string; 
  @ViewChild('viewAttachFileModal') modal: any;

  closeResult: string;
  modalReference: NgbModalRef;
  isLoading: boolean = true;
  srcUrl: string = "";

  constructor(public toast: ToastrService, public translate: TranslateService, private modalService: NgbModal) {
    if (!this.size) this.size = 'lg'; //size: sm, md, lg
    if (!this.notFoundContent) this.notFoundContent = 'global_attach_file_not_found';
    if (!this.title) this.title = 'global_view_attach_file';
    if (!this.fileType) this.fileType = 'file'; //file, image
  }
  ngOnInit(): void { 

  }

  contentLoaded() {
    this.isLoading = false;
  }

  open(fileUrl: string) {
    this.isLoading = true;
    this.srcUrl = fileUrl;
    
    if (!this.srcUrl) {
      this.isLoading = false;
    }

    this.modalReference = this.modalService.open(this.modal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: false,
      size: this.size
    });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  close() {
    this.modalReference.close();
  }

}

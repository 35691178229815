import CryptoJS from 'crypto-js';

export class Crypto {
    public static decode (string: string) {

        var key = "qwr{@^h`h&_`50/ja9!'dcmh3!uw<&=?";
        var iv = "9/\~V).A,lY&=t2b";
        
        key = CryptoJS.enc.Utf8.parse(key);
        iv = CryptoJS.enc.Utf8.parse(iv);

        var decrypted = CryptoJS.AES.decrypt(string, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    public static encode(string: string) {
        var key = "qwr{@^h`h&_`50/ja9!'dcmh3!uw<&=?";
        var iv = "9/\~V).A,lY&=t2b";
        
        key = CryptoJS.enc.Utf8.parse(key);
        iv = CryptoJS.enc.Utf8.parse(iv);

        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(string), key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        
        return encrypted.toString();
    }

    public static decodeUrl (string: string) {
        string = string.replace(/\[i\]/g, '/')
        let rsData = Crypto.decode(string) as string;
        let rs = rsData.split('&&')
    }
}

export class AES { 
    public static encode(string: string) {
        var iv = "wMsSeaFoOd@2021@iT2BdG";
        var key = "m8!'cr7m5!uw<&=?";
        
        key = CryptoJS.enc.Utf8.parse(key);
        iv = CryptoJS.enc.Utf8.parse(iv);

        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(string), key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        
        return encrypted.toString();
    }

    public static hash(string: string) {
        let hash = CryptoJS.MD5(string);
        return String(hash);
    }
}
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Basic } from '@core/lib/basic';
import { DataMinModel } from '@shared/models/global.model';

@Component({
  selector: 'selectbox-w-search',
  templateUrl: './selectbox-w-search.component.html',
  styleUrls: ['./selectbox-w-search.component.scss']
})
export class SelectboxWSearchComponent implements OnInit {

  @Input() data: any; //data to render selectbox
  @Input() searchId: any; // search Id for searching selectbox data (exclusively)
  @Input() title: string; // default title of selectbox
  @Input() titleByCode: string; // custom title of selectbox by "Key" have same value with Code field
  @Input() disabled: boolean; // disabled selectbox if True
  @Input() showTitleAsSelection: boolean; // add another selection as title if True
  @Input() showOptionByFields: string[]; // show options for selectbox by custom fields. Default "[name]"
  @Output() onSelect = new EventEmitter<any>(); // selection selectbox event

  searchKey: string = "";
  scrollbarConfig: PerfectScrollbarConfigInterface =  {};
  showingData: any = [];
  firstTitle: string = "";
  allData: DataMinModel;

  constructor() {
  }

  ngOnInit(): void {
    if (typeof this.disabled != "boolean") this.disabled = false;
    if (typeof this.showTitleAsSelection != "boolean") this.showTitleAsSelection = false;
    this.firstTitle = this.title;
    this.allData = {
      name: this.firstTitle,
      code: "",
      id: ""
    }
    if (!this.searchId) this.searchId = Basic.uuidGenerator();
    else this.searchId = this.searchId + Basic.uuidGenerator();
    if (!this.showOptionByFields) this.showOptionByFields = ["name"];
    if (this.titleByCode) this.checkAndCustomTitle();
  }

  ngOnChanges(): void {
    if (this.titleByCode && this.data) this.checkAndCustomTitle();
  }

  onClickDropdown(){
    this.searchKey = "";
    let inputElm = document.getElementById(this.searchId) as HTMLInputElement;
    inputElm.value = this.searchKey;
    this.showingData = this.data;
  }

  onChange($event){
    let id = $event.target.value;
    let data;
    for (let item of this.data){
      if (item.id === id) data = item;
    }
    this.onSelect.emit({
      id: id,
      data: data
    })
  }

  selectedRow(data){
    if (data !== this.allData) this.title = this.renderRowTitle(data);
    else this.title = data.name;
    this.onSelect.emit({
      id: data.id,
      data: data
    })
  }

  onKeyUpSearch($event){
    this.searchKey = decodeURIComponent($event.search_string);
    if (this.searchKey !== "") this.searchData(this.searchKey);
    else this.showingData = this.data;
  }

  searchData(key: string){
    this.showingData = [];
    for (let item of this.data){
      let name = this.renderRowTitle(item);
      if (name.toLowerCase().includes(key.toLowerCase())) this.showingData.push(item);
    }
  }

  refresh(){
    this.title = this.firstTitle;
  }

  renderRowTitle(data) {
    let title = "";
    for (let item of this.showOptionByFields) {
      title += data[item] + " ";
    }
    title = title.substring(0, title.length - 1);
    return title;
  }

  checkAndCustomTitle(){
    for (let item of this.data) {
      if (item["code"] === this.titleByCode) {
        this.title = this.renderRowTitle(item);
        break;
      }
    }
  }
}

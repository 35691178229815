
<nav class="sidebar sidebar-offcanvas" id="sidebar">
        <div class = "navbar" style="padding: 2.5rem 0; border-bottom: 1px #eee solid;">
            <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                <a class="navbar-brand brand-logo" routerLink="{{homeUrl}}"><img src="assets/images/logo/app-logo-hoz.png" alt="" class="img-fluid"/></a>
                <a class="navbar-brand brand-logo-mini" routerLink="{{homeUrl}}"><img src="assets/images/logo/app-logo.jpg" alt="" class="img-fluid"/></a>
            </div>
        </div>
        <ul class="nav">
            <li class="nav-item nav-profile" ngbDropdown style="border-bottom: 1px #eee solid; padding: 0;">
                <a class="nav-link cursor-pointer pt-2 pb-2" >
                    <div class="nav-profile-image pl-2" ngbDropdownToggle>
                        <img *ngIf="checkImg()" src="{{avatarUrl}}" alt="profile">
                        <span *ngIf="!checkImg()" style="height: 44px; width: 44px;" class="avatar-title bg-primary">{{userLastname.charAt(0)}}{{userFirstname.charAt(0)}}</span>                        
                    </div>
                    <form class="app-search nav-profile-text">
                        <div class="position-relative">
                            <input type="text" id="searchMenuInput" class="form-control" placeholder="{{'global_search-placeholder' | translate}}">
                            <span class="mdi mdi-magnify"></span>
                        </div>
                    </form>
                    <button class="btn btn-toggle-sidebar nav-profile-badge p-2" [tooltip]="'global_collapse' | translate" (click)="hiddenSidebar()">
                        <i class="mdi mdi-menu"></i>
                    </button> 
                </a>
                <div class="dropdown-menu navbar-dropdown dropdown-config" ngbDropdownMenu aria-labelledby="profileDropdown">
                    <a class="dropdown-item" [routerLink]="[userProfileUrl]">
                      <i class="mdi mdi-account-circle-outline mr-2 text-success"></i>
                      {{'auth_user-detail' | translate}}
                    </a>
                    <a class="dropdown-item" [routerLink]="[userUpdateUrl]">
                        <i class="mdi mdi-account-settings mr-2 text-primary"></i>
                        {{'auth_user-settings' | translate}}
                    </a>
                    <a class="dropdown-item" [routerLink]="[userChangePassUrl]">
                        <i class="mdi mdi-shield-key-outline mr-2" style="color: darkorange;"></i>
                        {{'auth_user-change_pass' | translate}}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" (click)="submitLogout()">
                      <i class="mdi mdi-logout mr-2 text-danger"></i>
                      {{'auth_logout' | translate}}
                    </a>
                </div>   
            </li>
            <!-- Expanded Sidebar Menu -->
            <perfect-scrollbar [hidden]="isCollapsedSidebar" class="scrollbar-config" [config]="scrollbarConfigSidebar">
                <div *ngFor="let data of showingSidebarMenuData">
                    <div *ngIf="data.childrend.length > 0 && data.isFolder">
                        <li class="nav-item" [ngClass]="{'active': checkActiveSidebarMenu(data)}">
                            <a class="nav-link" (click)="toggleExpand(data);" [attr.aria-expanded]="data.isExpand" attr.aria-controls="{{data.icon}}">
                                <div class="row w-100 pt-0 pb-0">
                                    <div class="col-3 pt-0 pb-0">
                                        <i class="mdi {{data.icon}} menu-icon"></i>
                                        <i class="menu-arrow"></i>
                                    </div>
                                    <div class="col-9 pt-0 pb-0">
                                        <span class="menu-title overflow-title">{{data.name}}</span>
                                    </div>
                                </div>
                            </a>
                            <div class="collapse" id="{{data.icon}}" [ngbCollapse]="!data.isExpand">
                                <ng-container *ngFor="let child of data.childrend">
                                    <ul class="nav flex-column sub-menu">
                                        <li class="nav-item"> 
                                            <a class="nav-link overflow-link" [tooltip]="child.name | translate" routerLink="{{child.layerUrl}}" [ngClass]="{'active': activeUrl.includes(child.layerUrl)}"><span class="mdi {{child.iconUrl}} mr-2"></span>{{child.name | translate}}</a>
                                        </li>
                                    </ul>
                                </ng-container>
                            </div>
                        </li>
                    </div>
                </div>
            </perfect-scrollbar>
            <!-- Collapsed Sidebar Menu -->
            <div [hidden]="!isCollapsedSidebar" style="height: calc(100vh - 165px);">
                <div *ngFor="let data of showingSidebarMenuData">
                    <div *ngIf="data.childrend.length > 0 && data.isFolder">
                        <li class="nav-item" 
                            [ngClass]="{'hover-open': hoverName == data.name, 'active': checkActiveSidebarMenu(data)}"
                            (mouseover)="hoverName=data.name" (mouseout)="hoverName=''"
                        >
                            <a class="nav-link" (click)="data.isExpand = !data.isExpand" [attr.aria-expanded]="data.isExpand" attr.aria-controls="{{data.icon}}">
                                <b class="menu-title overflow-title">{{data.name}}</b>
                                <i class="menu-arrow"></i>
                                <i class="mdi {{data.icon}} menu-icon"></i>
                            </a>
                            <div class="collapse" id="{{data.icon}}" [ngbCollapse]="!data.isExpand">
                                <ng-container *ngFor="let child of data.childrend">
                                    <ul class="nav flex-column sub-menu">
                                        <li class="nav-item"> 
                                            <a class="nav-link overflow-link pl-0 pr-0" [tooltip]="child.name | translate" routerLink="{{child.layerUrl}}" [ngClass]="{'active': activeUrl.includes(child.layerUrl)}"><span class="mdi {{child.icon}} mr-2"></span>{{child.name | translate}}</a>
                                        </li>
                                    </ul>
                                </ng-container>
                            </div>
                        </li>
                    </div>
                </div>
            </div>
            <div [hidden]="!isCollapsedSidebar" class="row introduced-title">
                <div class="col-12 pt-0 pb-0 text-right">
                    <p>{{appVersion}}</p>
                </div>
            </div>
        </ul>
        <div [hidden]="isCollapsedSidebar" class="row introduced-title">
            <div class="col-12 pt-0 pb-0 text-right">
                <p>{{appVersion}}</p>
            </div>
        </div>
</nav>

export enum AppPageRoutingEnum {
  HOME = "current-user",
  AUTH = "auth",
  USER_PROFILE = "current-user",
  ERROR = "error",
  SYSTEM_MANAGEMENT = "system-management",
  CATEGORY_MANAGEMENT = "category-management",
  WMS = "wms",
  HR_MANAGEMENT = "hrm"
}

export enum AppSubPageRoutingEnum {
  DETAIL = "detail",
  CREATE = "create",
  UPDATE = "update",
  LIST = "list",
}


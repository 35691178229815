export const Constant = {
    // Customer Info login
    CUSTOMER_INFO: "CustomerInfo",
    // Customer Ctrl
    CUSTOMER_GET_LIST: "Customer?fromDate={fromDate}&toDate={toDate}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    CUSTOMER_GET_PAGING: "Customer/listPaging?fromDate={fromDate}&toDate={toDate}&pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    CUSTOMER_CRUD: "Customer/{id}",
    CUSTOMER_GET_BY_CODE: "Customer/byCode/{code}",
    CUSTOMER_EXPORT: "Customer/exportExcel?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",

    // Customer & Account
    TRACKING_ACCOUNT_GET_LIST_BY_CUSTOMER: "CustomerAccountManage?customerCode={customerCode}&searchKey={searchKey}&fromDate={fromDate}&toDate={toDate}&sortOrder={sort}&sortBy={sortBy}",
    TRACKING_ACCOUNT_GET_LIST_PAGING_BY_CUSTOMER: "CustomerAccountManage/listPaging?customerCode={customerCode}&pageIndex={page}&pageSize={pageSize}&searchKey={searchKey}&sortOrder={sort}&sortBy={sortBy}",
    TRACKING_ACCOUNT_LOGIN: "CustomerAccountManage?userId={userId}",
    TRACKING_CUSTOMER_ACCOUNT_CRUD: "CustomerAccountManage/{id}",
    TRACKING_ACCOUNT_CONFIG: "CustomerAccountManage/users?customerCode={customerCode}",

    //Vehicle VehicleMonitoring Ctrl
    VEHICLE_MANAGEMENT_GET_LIST: "{customerCode}/VehicleMonitoring?fromDate={fromDate}&toDate={toDate}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&vehicleSignalStateCode={vehicleSignalStateCode}",
    VEHICLE_MANAGEMENT_GET_PAGING: "{customerCode}/VehicleMonitoring/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&vehicleSignalStateCode={vehicleSignalStateCode}",
    VEHICLE_MANAGEMENT_CRUD: "{customerCode}/VehicleMonitoring/{id}",
    VEHICLE_MANAGEMENT_GET_BY_CODE: "{customerCode}/VehicleMonitoring/byCode/{code}",
    VEHICLE_EXPORT: "{customerCode}/VehicleMonitoring/exportExcel?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    VEHICLE_NOT_IN_GROUP_GET_LIST: "{customerCode}/VehicleMonitoring?VehicleGroupCode={vhGroupCode}&vehicleSignalStateCode={vehicleSignalStateCode}",
    VEHICLE_GET_BY_USERID: "{customerCode}/VehicleMonitoring?userId={userId}&vehicleSignalStateCode={vehicleSignalStateCode}",

    //Vehicle VehicleMonitoring Ctrl
    VEHICLE_GET_LIST: "{customerCode}/Vehicle?fromDate={fromDate}&toDate={toDate}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    VEHICLE_GET_PAGING: "{customerCode}/Vehicle/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",

    //Vehicle Group Ctrl
    VEHICLE_GROUP_GET_LIST: "{customerCode}/VehicleGroup?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    VEHICLE_GROUP_GET_PAGING: "{customerCode}/VehicleGroup/listPaging?pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    VEHICLE_GROUP_CRUD: "{customerCode}/VehicleGroup/{id}",
    VEHICLE_GROUP_GET_BY_CODE: "{customerCode}/VehicleGroup/byCode/{code}",
    VEHICLE_GROUP_EXPORT: "{customerCode}/VehicleGroup/exportExcel?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",

     //Vehicle Group Manage
    VEHICLE_GROUP_MANAGEMENT_GET_BY_VEHICLE_GROUP: "{customerCode}/VehicleGroupManage?VehicleGroupCode={vhGroupCode}&fromDate={fromDate}&toDate={toDate}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    VEHICLE_GROUP_MANAGEMENT_CRUD: "{customerCode}/VehicleGroupManage/{id}",

    //Vehicle Device Manage
    VEHICLE_DEVICE_GET_BY_VEHICLE_CODE: "{customerCode}/VehicleDeviceManage?vehicleCode={vehicleCode}",
    VEHICLE_DEVICE_CRUD: "{customerCode}/VehicleDeviceManage/{id}",

    //Vehicle Account Manage    
    VEHICLE_ACCOUNT_GET_LIST_BY_ACCOUNT_ID: "{customerCode}/VehicleAccountManage?userId={userId}",
    VEHICLE_ACCOUNT_GET_LIST_PAGING_BY_ACCOUNT_ID: "{customerCode}/VehicleAccountManage/listPaging?userId={userId}&pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    VEHICLE_ACCOUNT_GET_BY_VEHICLE_CODE: "{customerCode}/VehicleAccountManage?vehicleCode={vehicleCode}",
    VEHICLE_ACCOUNT_CRUD: "{customerCode}/VehicleAccountManage/{id}",
    VEHICLE_ACCOUNT_EXPORT: "{customerCode}/VehicleAccountManage/exportExcel?userId={userId}",

    // Category Ctrl
    CATEGORY_MANAGEMENT_GET_LIST: "{customerCode}/{cateType}?fromDate={fromDate}&toDate={toDate}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    CATEGORY_MANAGEMENT_GET_PAGING: "{customerCode}/{cateType}/listPaging?fromDate={fromDate}&toDate={toDate}&pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    CATEGORY_MANAGEMENT_CRUD: "{customerCode}/{cateType}/{id}",
    CATEGORY_MANAGEMENT_GET_BY_CODE: "{customerCode}/{cateType}/byCode/{code}",
    CATEGORY_EXPORT: "{customerCode}/{cateType}/exportExcel?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",

    //Device Ctrl
    DEVICE_MANAGEMENT_GET_LIST: "{customerCode}/Device?fromDate={fromDate}&toDate={toDate}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    DEVICE_GET_LIST_BY_VEHICLECODE: "{customerCode}/Device?vehicleCode={vehicleCode}",
    DEVICE_MANAGEMENT_GET_PAGING: "{customerCode}/Device/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    DEVICE_MANAGEMENT_CRUD: "{customerCode}/Device/{id}",
    DEVICE_MANAGEMENT_GET_BY_CODE: "{customerCode}/Device/byCode/{code}",
    DEVICE_EXPORT: "{customerCode}/Device/exportExcel?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",

    //GeoZone Ctrl
    GEOZONE_GET_LIST: "{customerCode}/GeoZone?fromDate={fromDate}&toDate={toDate}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    GEOZONE_GET_PAGING: "{customerCode}/GeoZone/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    GEOZONE_CRUD: "{customerCode}/GeoZone/{id}",
    GEOZONE_GET_BY_CODE: "{customerCode}/GeoZone/byCode/{code}",
    GEOZONE_EXPORT: "{customerCode}/GeoZone/exportExcel?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",

    //GeoZone Permit Ctrl
    GEOZONE_PERMIT_GET_LIST: "{customerCode}/GeoZonePermit?fromDate={fromDate}&toDate={toDate}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    GEOZONE_PERMIT_GET_PAGING: "{customerCode}/GeoZonePermit/listPaging?pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    GEOZONE_PERMIT_CRUD: "{customerCode}/GeoZonePermit/{id}",
    GEOZONE_PERMIT_GET_BY_CODE: "{customerCode}/GeoZonePermit/byCode/{code}",
    GEOZONE_PERMIT_EXPORT: "{customerCode}/GeoZonePermit/exportExcel?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    GEOZONE_PERMIT_GET_BY_VEHICLE: "{customerCode}/GeoZonePermit?vehicleCodes={vehicleCodes}",

    //Vehicle Tracking
    VEHICLE_TRACKING_GET_LIST: "{customerCode}/{agentDeviceCode}VehicleTracking?code={code}&isLatest={isLatest}&deviceCode={deviceCode}&vehicleCodes={vehicleCodes}&fromDate={fromDate}&toDate={toDate}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    VEHICLE_TRACKING_GET_LIST_PAGING: "{customerCode}/{agentDeviceCode}VehicleTracking/listPaging?code={code}&deviceCode={deviceCode}&vehicleCodes={vehicleCodes}&fromDate={fromDate}&toDate={toDate}&pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    VEHICLE_TRACKING_CRUD: "{customerCode}/{agentDeviceCode}VehicleTracking/{id}",
    VEHICLE_TRACKING_GET_BY_CODE: "{customerCode}/{agentDeviceCode}VehicleTracking/byCode/{code}",
    VEHICLE_TRACKING_EXPORT: "{customerCode}/{agentDeviceCode}VehicleTracking/exportExcel?code={code}&deviceCode={deviceCode}&vehicleCodes={vehicleCodes}&fromDate={fromDate}&toDate={toDate}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    VEHICLE_TRACKING_IMPORT: "{customerCode}/{agentDeviceCode}VehicleTracking/importExcel",

    //Vehicle Tracking Monitoring
    VEHICLE_TRACKING_MONITORING_GEOZONE_GET_LIST: "{customerCode}/{agentDeviceCode}VehicleTrackingMonitoring/warning?vehicleCodes={vehicleCodes}&fromDate={fromDate}&toDate={toDate}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    VEHICLE_TRACKING_MONITORING_GEOZONE_LIST_PAGING: "{customerCode}/{agentDeviceCode}VehicleTrackingMonitoring/warning/listPaging?vehicleCodes={vehicleCodes}&fromDate={fromDate}&toDate={toDate}&pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    VEHICLE_TRACKING_MONITORING_GEOZONE_EXPORT: "{customerCode}/{agentDeviceCode}VehicleTrackingMonitoring/warning/exportExcel?vehicleCodes={vehicleCodes}&fromDate={fromDate}&toDate={toDate}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    VEHICLE_TRACKING_MONITORING_STATISTIC: "{customerCode}/{agentDeviceCode}VehicleTrackingMonitoring/warningStatistic?vehicleSignalStateCode={vehicleSignalStateCode}",
    VEHICLE_TRACKING_MONITORING_STATISTIC_DETAIL: "{customerCode}/{agentDeviceCode}VehicleTrackingMonitoring/warningStatistic/detail?vehicleSignalStateCode={vehicleSignalStateCode}",
    VEHICLE_TRACKING_MONITORING_STATISTIC_DETAIL_LIST_PAGING: "{customerCode}/{agentDeviceCode}VehicleTrackingMonitoring/warningStatistic/detail/listPaging?vehicleSignalStateCode={vehicleSignalStateCode}&pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",

    //Signal Buoys Monitoring (VehicleMonitoring)
    SIGNAL_BOUY_GET_LIST: "{customerCode}/VehicleMonitoring?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&vehicleSignalStateCode={vehicleSignalStateCode}",
    SIGNAL_BOUY_GET_LIST_PAGING: "{customerCode}/VehicleMonitoring/listPaging?pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&vehicleSignalStateCode={vehicleSignalStateCode}",
    SIGNAL_BOUY_CRUD: "{customerCode}/VehicleMonitoring/{id}",
    SIGNAL_BOUY_GET_BY_CODE: "{customerCode}/VehicleMonitoring/byCode/{code}",
    SIGNAL_BOUY_EXPORT: "{customerCode}/VehicleMonitoring/exportExcel?sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&vehicleSignalStateCode={vehicleSignalStateCode}",
    SIGNAL_BOUY_GET_BY_USERID: "{customerCode}/VehicleMonitoring?userId={userId}",
    SIGNAL_BOUY_GET_LIST_SIGNALS: "DataLayers/Signal?waterWayCode={waterWayCode}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&signalCategoryCode={signalCategoryCode}&isExportMin=true",
    SIGNAL_BOUY_GET_LIST_PAGING_SIGNALS: "DataLayers/Signal/listPaging?waterWayCode={waterWayCode}&pageIndex={page}&pageSize={pageSize}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&signalCategoryCode={signalCategoryCode}",
}
<div ngbDropdown class="d-inline-block custom-dropdown-menu">
    <button class="dropdown-menu-toggle" id="dropdownSelectbox" [disabled]="disabled" (click)="onClickDropdown()" ngbDropdownToggle>
        <span class="text-overflow pl-3" [tooltip]="title | translate">{{ title | translate}}</span>
        <i class="mdi mdi-chevron-down dropdown-icon"></i>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownSelectbox">
        <div class="dropdown-input pl-2 pr-2 mb-2">
            <searchbox [id]='searchId' [value]="searchKey" (onSearch)="onKeyUpSearch($event)"></searchbox>
        </div>
        <perfect-scrollbar class="scrollView perf-scrollbar" fxFlex="auto" [config]="scrollbarConfig">
            <div *ngIf="showTitleAsSelection" ngbDropdownItem class="row ml-2 dropdown-items" (click)="selectedRow(allData)">
                <div class="col-12 text-overflow" [tooltip]="allData.name | translate">
                    {{allData.name | translate}}
                </div>
            </div>
            <div *ngFor="let item of showingData">
                <div ngbDropdownItem class="row ml-2 dropdown-items" (click)="selectedRow(item)">
                    <div class="col-12 text-overflow" [tooltip]="renderRowTitle(item)">
                        {{renderRowTitle(item)}}
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</div>
<ng-template #confirmModal let-modal>
    <div class="modal-header m-auto" *ngIf="type">
      <span *ngIf="type == 'normal'" class="mdi mdi-information-outline text-secondary icon-img"></span>
      <span *ngIf="type == 'insert'" class="mdi mdi-shape-circle-plus text-primary icon-img"></span>
      <span *ngIf="type == 'update'" class="mdi mdi-progress-check text-success icon-img"></span>
      <span *ngIf="type == 'delete'" class="mdi mdi-delete-empty-outline text-danger icon-img"></span>
    </div>
    <div class="modal-body">
      <b [style]="titleStyle">{{title | translate}}</b>
      <p [style]="contentStyle" class="sub-title mt-2">{{content | translate}}</p>
      <input *ngIf="inputType == 'input-text'" type="text" class="form-control mb-2" [(ngModel)]="inputValue">
      <textarea *ngIf="inputType == 'textarea'" class="confirm-textarea" rows="4" [(ngModel)]="inputValue"></textarea>
    </div>
    <div class="modal-footer footer-confirm-input">
      <button type="button" class="btn btn-light p-2" (click)="modal.close('Save click')">{{'global_cancel' | translate}}</button>
      <button type="button" [disabled]="!inputValue" class="btn btn-success mr-2 p-2" (click)="clickLink()">{{'global_submit' | translate}}</button>
    </div>
</ng-template>
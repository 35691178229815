import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Constant } from '@core/constant/api-system/Constant';
import { AuthService } from '@core/services/root/auth/auth.service';
import { CacheAll } from '@core/lib/cache';
import { UserDataModel } from '@shared/models/auth.model';
import { UpdateUserModel, ChangePassUserModel } from '../models/user.model';
import { CacheConst } from '@core/constant/shared/system.const';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  systemApiUrl = environment.SYSTEM_API_URL;
  //auth
  userId: string;
  
  userSample: UserDataModel = {
    birthday: "",
    address: "",
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    role: {
        code: "",
        name: "",
        id: ""
    },
    updatedAt: "",
    status: { 
      code: "",
      name: "",
      id: ""
    },
    statusUpdAt: "",
    id: "",
    createdAt: "",
    department: {
      code: "",
      name: "",
      id: ""
    },
    position: {
      code: "",
      name: "",
      id: ""
    }, 
    avatarUrl: "",
    phone: "",
    secondPhone: "",
    gender: {
      code: "",
      name: "",
      id: ""
    }
  }

  constructor( private http: HttpClient, private authService: AuthService) {
    this.userId = CacheAll.getCookie(CacheConst.USER_ID)
  }

  getUserDetail(){
    const url = this.systemApiUrl.concat(Constant.USER_DETAIL).replace('{user_id}', this.userId);
    return this.http.get(url);
  }

  updateUser(data: UpdateUserModel){
    const url = this.systemApiUrl.concat(Constant.USER_UPDATE).replace('{user_id}', this.userId);
    return this.http.put(url, data);
  }

  changePassUser(data: ChangePassUserModel){
    const url = this.systemApiUrl.concat(Constant.USER_CHANGE_PASS).replace('{user_id}', this.userId);
    return this.http.post(url, data);
  }

  uploadAvatar(file: any){
    const formData = new FormData();
    formData.append('files', file);
    formData.append('FileType', 'Avatar');
    
    const url = this.systemApiUrl.concat(Constant.UPLOAD_NEW);
    return this.http.post(url, formData);
  }
}
